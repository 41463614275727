import axios from 'axios';
import {React, useState } from 'react';
import { Form, Button}  from 'react-bootstrap';

export default function Contact (props) {
	const success = 'Thank you for your enquiry, we will contact you directly'
	const failure = 'Sorry. We were unable to send your message'
	const [notification, setNotification] = useState('')
	const [inputs, setInputs] = useState({});

	const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const username = event.target.username;
    const email = event.target.email;
    const message = event.target.message;

    setInputs(values => ({...values, [name]: value}))
  }

	const onSubmit = async (e) => {
		e.preventDefault();		
		const url = window.location.origin.replace(':3007',':8000')+'/contact.php'
		// const url = 'http://localhost:8000/contact.php'
		const args = '?username='+inputs.username+'&email='+inputs.email+'&message='+inputs.message
		console.log(args)
		const response = await axios.post(url+args)
		console.log(response.data)
		setNotification(response.data > 0 ? success : failure)
	}  

	const style = {marginTop : 12}
	return (
		<div>
			<Form onSubmit={onSubmit} style={{marginTop : 20, marginBottom : 20}}>
				<Form.Group className="mb-3 center" style={{maxWidth : 500, maxHeight : 500 }}>
					<h3>Access to Theseus is by Invitation Only</h3>
					<Form.Label style = {style} >Please complete the form below to contact us directly</Form.Label><br/>
					<Form.Control style = {style} name='username' placeholder="Name" value={inputs.username || ''} onChange={handleChange}/>
					<Form.Control style = {style} name='email' placeholder="Email" value={inputs.email || ''} onChange={handleChange}/>
					<Form.Control style = {style} name='message' placeholder="Message" value={inputs.message || ''} onChange={handleChange}/>
					<Button variant='secondary' id = 'contact.submit' onClick={props.onClick}>Submit</Button>
					<Form.Label style = {{marginTop : 10, color : 'darkgray', fontSize : 14 }}>{notification}</Form.Label>
				</Form.Group>
		</Form>
		</div>
	);
}
